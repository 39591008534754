import React from 'react';
import * as styles from './BulletPoint.module.scss';
import {Typography} from "../Typography";
import classNames from 'classnames';
import {SVGSupportImg} from "../SVGSupportImg";
import {componentRef} from "../../functions/componentRef";

export function BulletPoint({title, children, appearance = 'horizontal', className, image, ...otherProps}) {
    return <article className={classNames(styles.root, className)} data-appearance={appearance} {...otherProps} {...componentRef('bullet-point')}>
        <div className={styles.imageWrapper}>
            <SVGSupportImg className={styles.image} {...image} alt={title}/>
        </div>
        <div className={styles.contentWrapper}>
            <h1 className={styles.title} {...componentRef.subcomponent('bullet-point-title')} >{title}</h1>
            <Typography className={styles.content}>
                {children}
            </Typography>
        </div>
    </article>
}