import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {ServiceHero} from "../components/ServiceHero";
import {PromoImages} from "../components/PromoImages";
import * as styles from './service.module.scss';
import {BlockTitle} from "../components/BlockTitle";
import {Typography} from "../components/Typography";
import {Card} from "../components/Card";
import {ExternalLogosExhibition} from "../components/ExternalLogosExhibition";
import {ExternalLogo} from "../components/ExternalLogo";
import {Button} from "../components/Button";
import {TestimonialHero} from "../components/TestimonialHero";
import {BulletPoint} from "../components/BulletPoint";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {LangLink} from "../components/LangLink";
import {useIntl} from 'gatsby-plugin-react-intl';
import {createSeoData} from "../functions/createSeoData";

export const query = graphql`query Service($locale: String!, $slug: String!) {
    service: contentfulService(slug: {eq: $slug}, node_locale: {eq: $locale}) {
        name
        icon
        heroTitle
        heroContent {
            childMarkdownRemark {
                html
            }
        }
        promoImages {
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 515, height: 460, placeholder: BLURRED, layout: FIXED)
                }
            }
        }
        explanationTitle
        explanationContent {
            childMarkdownRemark {
                html
            }
        }
        recommendationsTitle
        recommendationsContent {
            childMarkdownRemark {
                html
            }
        }
        recommendationsLogos {
            localFile {
                url
                childImageSharp {
                    gatsbyImageData(
                        width: 150
                        height: 80
                        backgroundColor: "transparent"
                        placeholder: BLURRED
                        transformOptions: {fit: CONTAIN}
                        layout: FIXED
                    )
                }
            }
        }
        benefitsTitle
        benefitsContent {
            childMarkdownRemark {
                html
            }
        }
        benefits {
            title
            content {
                childMarkdownRemark {
                    html
                }
            }
            icon {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(width: 52, height: 52, placeholder: BLURRED, layout: FIXED)
                    }
                }
            }
        }
        testimonialTitle
        testimonialContent {
            childMarkdownRemark {
                html
            }
        }
        testimonial {
            intro {
                intro
            }
            content {
                content
            }
            personName
            personPosition
            personCountry
            heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1106
                            height: 622
                            placeholder: BLURRED
                            transformOptions: {fit: COVER}
                            layout: FIXED
                        )
                    }
                }
            }
            logo {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(
                            width: 150
                            height: 80
                            backgroundColor: "transparent"
                            placeholder: BLURRED
                            transformOptions: {fit: CONTAIN}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        ctaTitle
        ctaContent {
            childMarkdownRemark {
                html
            }
        }
        seo {
            ...Seo
        }
    }
}
`

export default function ServicePage({data, pageContext}) {
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.service.name}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(data.service.seo)}
                   companyData={pageContext.companyData}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <ServiceHero title={data.service.heroTitle}
                         name={data.service.name}
                         icon={data.service.icon}>
                <MarkdownContent content={data.service.heroContent}/>
            </ServiceHero>
            <PromoImages>
                {data.service.promoImages ? data.service.promoImages
                    .filter(x => x.localFile)
                    .map((image, index) => {
                        return <PromoImages.Image image={image.localFile} key={index}/>;
                    }) : undefined}
            </PromoImages>
            <WhatIsIt title={data.service.explanationTitle}>
                <MarkdownContent content={data.service.explanationContent}/>
            </WhatIsIt>
            <Recommendations title={data.service.recommendationsTitle} logos={
                data.service.recommendationsLogos ? data.service.recommendationsLogos.map(x => x.localFile) : []
            }>
                <MarkdownContent content={data.service.recommendationsContent}/>
            </Recommendations>

            <Benefits title={data.service.benefitsTitle}
                      description={<MarkdownContent content={data.service.benefitsContent}/>}>
                {data.service.benefits ? data.service.benefits.map((data, index) => {
                    return <BulletPoint image={data.icon.localFile}
                                        key={index}
                                        title={data.title}>
                        <MarkdownContent content={data.content}/>
                    </BulletPoint>
                }) : undefined}
            </Benefits>
            {renderTestimonialDescription(data.service.testimonialTitle, data.service.testimonialContent)}
            {renderTestimonial(data.service.testimonial)}
            <Cta title={data.service.ctaTitle}>
                <MarkdownContent content={data.service.ctaContent}/>
            </Cta>
        </PageMainColumnWrapper>
    </Layout>
}

function renderTestimonial(testimonial) {
    if (!testimonial) {
        return;
    }
    return <TestimonialHero
        image={testimonial.heroImage.localFile}
        intro={testimonial.intro.intro}
        description={testimonial.content.content}
        logo={testimonial.logo.localFile}
        person={{
            name: testimonial.personName,
            position: testimonial.personPosition,
            country: testimonial.personCountry
        }}/>;
}

function renderTestimonialDescription(title, content) {
    const isTitleEmpty = !title;
    const isContentEmpty = !content || (content && content.childMarkdownRemark);
    if (isTitleEmpty && isContentEmpty) {
        return;
    }
    return <TestimonialDescription title={title}>
        <MarkdownContent content={content}/>
    </TestimonialDescription>;
}

function WhatIsIt({title, children}) {
    return <section className={styles.whatIsIt}>
        <BlockTitle className={styles.whatIsIt_title}>{title}</BlockTitle>
        <Typography className={styles.whatIsIt_content}>
            {children}
        </Typography>
    </section>
}

function Recommendations({title, logos, children}) {
    return <Card className={styles.recommendations}>
        <BlockTitle className={styles.recommendations_title}>{title}</BlockTitle>
        <Typography emphasizeFirstParagraph className={styles.recommendations_content}>
            {children}
        </Typography>
        <ExternalLogosExhibition className={styles.recommendations_logos}>
            {logos.map(
                (logo, index) => {
                    return <ExternalLogo {...logo} key={index}/>;
                }
            )}
        </ExternalLogosExhibition>
    </Card>
}

function Cta({children, title}) {
    const intl = useIntl();
    return <Card className={styles.cta}>
        <BlockTitle className={styles.cta_title}>{title}</BlockTitle>
        <Typography>
            {children}
        </Typography>
        <Button to="/contact" component={LangLink} className={styles.cta_button}>
            {intl.formatMessage({id: 'button.contactCTA'})}
        </Button>
    </Card>
}

function Benefits({title, description, children}) {
    return <section className={styles.benefits}>
        <div className={styles.benefits_wrapper}>
            <BlockTitle className={styles.benefits_title}>{title}</BlockTitle>
            <Typography>{description}</Typography>
            <div className={styles.benefits_points}>
                {children}
            </div>
        </div>
    </section>
}

function TestimonialDescription({title, children}) {
    return <section className={styles.testimonialDescription}>
        <BlockTitle className={styles.testimonialDescription_title}>{title}</BlockTitle>
        <Typography>
            {children}
        </Typography>
    </section>
}
