import * as styles from './ServiceHero.module.scss';
import React from 'react';
import classNames from 'classnames';
import {Breadcrumbs} from "../Breadcrumbs";
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {ServiceIcon} from "../ServiceIcon";
import {useIntl} from 'gatsby-plugin-react-intl';
import {componentRef} from "../../functions/componentRef";

export function ServiceHero({title, icon, className, name, children, ...otherProps}) {
    const intl = useIntl();
    return <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('service-hero')}>
        <div className={styles.content}>
            <Breadcrumbs className={styles.breadcrumbs}>
                <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                <Breadcrumbs.Item isLast={true}>{name}</Breadcrumbs.Item>
            </Breadcrumbs>
            <BlockTitle className={styles.title}>{title}</BlockTitle>
            <Typography>
                {children}
            </Typography>
        </div>

        <ServiceIcon icon={icon} size="big" className={styles.icon}/>
    </section>
}