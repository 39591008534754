import React from 'react';
import * as styles from './ExternalLogosExhibition.module.scss';
import classNames from 'classnames';

export function ExternalLogosExhibition({className, children, ...otherProps}) {
    return <div className={classNames(styles.root, className)} {...otherProps}>
        {React.Children.map(children, child => {
            return React.cloneElement(child, {
                ...child.props,
                className: classNames(child.props.className, styles.item)
            });
        })}
    </div>;
}