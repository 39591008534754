// extracted by mini-css-extract-plugin
export var benefits = "service-module--benefits--NQcnd";
export var benefits_points = "service-module--benefits_points---UAUh";
export var benefits_title = "service-module--benefits_title--ugchJ";
export var benefits_wrapper = "service-module--benefits_wrapper--DxaQm";
export var cta = "service-module--cta--JDSpj";
export var cta_button = "service-module--cta_button--u95VI";
export var cta_title = "service-module--cta_title--2AoAk";
export var recommendations = "service-module--recommendations--4WwkY";
export var recommendations_content = "service-module--recommendations_content--jqH-F";
export var recommendations_logos = "service-module--recommendations_logos--Q3siG";
export var recommendations_title = "service-module--recommendations_title--l3C37";
export var testimonialDescription = "service-module--testimonialDescription--c5jug";
export var testimonialDescription_title = "service-module--testimonialDescription_title--jwWzo";
export var whatIsIt = "service-module--whatIsIt--z0u8w";
export var whatIsIt_content = "service-module--whatIsIt_content--B0KAi";
export var whatIsIt_title = "service-module--whatIsIt_title--y51hQ";